import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../shared/services/notification.service";
import {StudentService} from "../../shared/services/student.service";
import {Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";

@Component({
  selector: 'ps-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  id: any;
  notifications: any = [];
  user: any;
  student: any;

  constructor(private notificationService: NotificationService,
              private router: Router,
              private studentService: StudentService) {
    this.user = StorageService.getItem('self');
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.getStudentDetails();
  }

  getStudentDetails() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.student = data;
        if (this.student && !this.student.registration_no) {
          this.router.navigateByUrl('/general/home');
        }
      }
      this.get();
    });
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    params.status = 'ACTIVE';
    params.college_id = this.user.college_id;
    params.notified_for = 'STUDENT';
    this.notifications = [];
    this.notificationService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      }
    });
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
