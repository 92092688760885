import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {StorageService} from './shared/services/storage.service';
import {GlobalService} from './shared/services/global.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './shared/services/auth.service';
import {filter} from 'rxjs/operators';
import {AppConstants} from './shared/constants/app-constant';

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'ums';
  exceptionRoutes: any = AppConstants.exceptionRoutes;
  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {
    this._unsubscribeAll = new Subject();
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.router.url || !this.exceptionRoutes.includes(this.router.url.split('/')[1])) {
          this.checkLogin();
        }
      }
    });


    this.globalService.reloadSelf.subscribe((d) => {
      if (d) {
        this.reloadSelf();
        this.globalService.reloadSelf.next(false);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'college_id': data.college_id,
        'session_id': data.session_id,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }
}
